import React, { Component } from "react"
import Select from 'react-select'
import YouTube from 'react-youtube'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import iconSet from '../../site_icons/selection.json'
import IcomoonReact, { iconList } from 'icomoon-react'
import FixedMenu from "../components/FixedMenu" 
import SEO from "../components/seo"

import BackButton from '../components/BackButton'
import SiteContext from "../context/SiteContext"

class ProductPage extends Component {
    
    static contextType = SiteContext;

    constructor(props) {
        super(props)
        this.state = {
            online: true,
            benefits: '',
            features: '',
            uses: '',
            packaging:'',
            coverage:'',
            downloads: [],
            relatedProductBulletins: [],
            relatedBrochures: [],
            relatedTechnicalBulletins: [],
            relatedSafetyDataSheets: [],
            relatedCodeReports: [],
            relatedSpecifications: [],
            related2dPdfs: [],
            related3dPdfs: [],
            relatedSystemOverviews: [],
        }
    }

    componentDidMount() {
        let site = 'senergy'
        
        this.context.changeSite(site)
        
        console.log('mount', this.props.pageContext.productData)
        const {
            benefits,
            features,
            uses,
            packaging,
            coverage,
            downloads,
            relatedProductBulletins,
            relatedBrochures,
            relatedTechnicalBulletins,
            relatedSafetyDataSheets,
            relatedCodeReports,
            relatedSpecifications,
            related2dPdfs,
            related3dPdfs,
            relatedSystemOverviews,
        } = this.props.pageContext.productData

        // console.log('benefits', benefits)

        this.setState({
            benefits,
            features,
            uses,
            packaging,
            coverage,
            downloads,
            relatedProductBulletins,
            relatedBrochures,
            relatedTechnicalBulletins,
            relatedSafetyDataSheets,
            relatedCodeReports,
            relatedSpecifications,
            related2dPdfs,
            related3dPdfs,
            relatedSystemOverviews
        }, () => {
            // console.log('state', this.state)
        })


    }


    renderFeatures = () => {
        return (
            <div className="app-page-content">
                <div className="app-section-title">Features</div>
                <div dangerouslySetInnerHTML={{ __html: this.state.uses }}></div>
            </div>
        )
    }

    renderBenefits = () => {
        return (
            <div className="app-page-content">
                <div className="app-section-title">Benefits</div>
                <div dangerouslySetInnerHTML={{ __html: this.state.benefits }}></div>
            </div>
        )
    }

    renderUses = () => {
        return (
            <div className="app-page-content">
                <div className="app-section-title">Uses</div>
                <div dangerouslySetInnerHTML={{ __html: this.state.uses }}></div>
            </div>
        )
    }

    renderPackaging = () => {
        return (
            <div className="app-page-content">
                <div className="app-section-title">Packaging</div>
                <div dangerouslySetInnerHTML={{ __html: this.state.packaging }}></div>
            </div>
        )
    }

    renderCoverage = () => {
        return (
            <div className="app-page-content">
                <div className="app-section-title">Coverage</div>
                <div dangerouslySetInnerHTML={{ __html: this.state.coverage }}></div>
            </div>
        )
    }

    renderDownloads = (type, siteContext) => {
        let stateArray = []
        let downloadIcon = 'documents';

        switch (type) {
            case 'Product Bulletins':
                stateArray = this.state.relatedProductBulletins
                downloadIcon = "product-bulletins"
                break
            case 'Brochures':
                stateArray = this.state.relatedBrochures
                downloadIcon = "brochures"
                break
            case 'Technical Bulletins':
                stateArray = this.state.relatedTechnicalBulletins
                downloadIcon = "technical-bulletins"
                break
            case 'Safety Data Sheets':
                stateArray = this.state.relatedSafetyDataSheets
                downloadIcon = "sds"
                break
            case 'Code Reports':
                stateArray = this.state.relatedCodeReports
                downloadIcon = "code-reports"
                break
            case 'Specifications':
                stateArray = this.state.relatedSpecifications
                downloadIcon = "specifications"
                break
            case 'Details':
                stateArray = this.state.related2dPdfs.concat(this.state.related3dPdfs)
                downloadIcon = "details"
                break
            case 'System Overviews':
                stateArray = this.state.relatedSystemOverviews
                downloadIcon = "system-overviews"
                break
            default:
                break
        }
        return (

            <div className="download-group">
                <div className="download-group__header">
                    <IcomoonReact className="download-group__icon" iconSet={iconSet} size={24} icon={downloadIcon} color="#ffc510"/>
                    <div className="download-group__title">{type}</div>
                </div>
                {stateArray.map(item => {
                    return <div key={item.id}>
                        <div className="download">
                            <div className="download__copy">
                                <span className="download__title">{item.title}</span>
                            </div>
                            <div className="download__links">
                                <a className="download__link" href={item.filepath} target="_blank" rel="noopener noreferrer">
                                    <IcomoonReact className="download__icon" iconSet={iconSet} size={16} icon="download-thin" />
                                </a>
                                <a className="download__link" href={`mailto:add_email_here@email.com?subject=${item.title}&body=I%20found%20this%20download%20on%20the%20Senergy%20app.%20Check%20it%20out: ${item.externalLink ? item.externalLink : item.filepath}`} target="_blank" rel="noopener noreferrer">
                                    <IcomoonReact className="download__icon" iconSet={iconSet} size={16} icon="email" />
                                </a>
                            </div>
                        </div>
                    </div>
                })}
            </div>
        )
    }

    render() {
        const { productData } = this.props.pageContext
        // console.log('productData', productData)

        // console.log('product props', this.props.pageContext)

        const opts = {
            height: '260',
            width: '510',
            playerVars: { // https://developers.google.com/youtube/player_parameters
                autoplay: 0
            }
        }

        const regex = /(<([^>]+)>)/ig 

        return (
            <SiteContext.Consumer>
                {siteContext => (
                    <>
                        <SEO title={productData.title} />
                        <div className="wrapper product-page">
                            <div className="app-header-style-image">
                                <div className="app-header">
                                    <div className="app-header-bg" style={{backgroundImage: `url(${productData.imagePath}?width=960)` }}></div>
                                    <div className="app-header-content">
                                        <h1 className="app-header__title title--left">{productData.title}</h1>
                                        <div className="app-header__description" dangerouslySetInnerHTML={{ __html: productData.description }}></div>
                                    </div>     
                                </div>
                            </div> 
                            <div className="content-wrapper--padded-xs-top">

                                {
                                    this.state.features
                                        ? this.renderFeatures()
                                        : null
                                }
                                {
                                    this.state.benefits
                                        ? this.renderBenefits()
                                        : null
                                }
                                {
                                    this.state.uses
                                        ? this.renderUses()
                                        : null
                                }
                                {
                                    this.state.packaging
                                        ? this.renderPackaging()
                                        : null
                                }
                                {
                                    this.state.coverage
                                        ? this.renderCoverage()
                                        : null
                                }

                                <div className="app-section-title">Downloads</div>

                                
                                {
                                    this.state.relatedProductBulletins.length
                                        ? this.renderDownloads('Product Bulletins', siteContext)
                                        : null
                                }
                                {
                                    this.state.relatedBrochures.length
                                        ? this.renderDownloads('Brochures', siteContext)
                                        : null
                                }
                                {
                                    this.state.relatedTechnicalBulletins.length
                                        ? this.renderDownloads('Technical Bulletins', siteContext)
                                        : null
                                }
                                {
                                    this.state.relatedSafetyDataSheets.length
                                        ? this.renderDownloads('Safety Data Sheets', siteContext)
                                        : null
                                }
                                {
                                    this.state.relatedSpecifications.length
                                        ? this.renderDownloads('Specifications', siteContext)
                                        : null
                                }
                                {
                                    this.state.relatedCodeReports.length
                                        ? this.renderDownloads('Code Reports', siteContext)
                                        : null
                                }
                                {
                                    this.state.related2dPdfs.length || this.state.related3dPdfs && this.state.related3dPdfs.length
                                        ? this.renderDownloads('Details', siteContext)
                                        : null
                                }
                        
                                {productData.relatedVideos.length
                                    ? <div className="download-group">
                                        <div className="download-group__header">
                                            <IcomoonReact className="download-group__icon" iconSet={iconSet} size={24} icon="videos" color="#ffc510"/>
                                            <div className="download-group__title">Videos</div>
                                        </div>
                                        {productData.relatedVideos.map(video => {
                                            return (
                                                <div className="video-wrapper">
                                                    <div className="video video-margin-bottom" key={video.id}>
                                                        {this.state.online ?
                                                            <YouTube
                                                                videoId={video.youtubeEmbedId}
                                                                opts={opts}
                                                                onReady={this._onReady}
                                                            />
                                                            : ''
                                                        }
                                                    </div>
                                                    <div className="video-details">
                                                        <span className="video-details__title">{video.title}</span>
                                                        <span className="video-details__summary">{video.summary !== null ? video.summary.replace(regex, '') : null}</span>
                                                        <a className="video-details__link" href={`mailto:?subject=Check%20out%20the%20video%20${video.title}%20from%20BASF&body=I%20found%20this%20video%20on%20the%20Master%20Builders%20Solutions%20app.%20Check%20it%20out: https://www.youtube.com/watch?v=${video.youtubeEmbedId}`} target="_blank" rel="noopener noreferrer">
                                                            <IcomoonReact className="video-details__icon" iconSet={iconSet} size={24} icon="email"/>
                                                            Email this video
                                                        </a>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    : null
                                } 
                            </div>
                            <FixedMenu active="products" site={siteContext.site} /> 
                        </div>
                    </>
                )}
            </SiteContext.Consumer>
        )
    }
}

export default ProductPage